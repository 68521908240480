<template>
    <div id="web-chat"></div>
  </template>
  
  <script>
  export default {
    name: 'WebChat',
    data() {
      return {
        cozeWebSDK: null, // 用于存储 SDK 实例
      };
    },
    mounted() {
      this.initWebChat();
      
    },
    methods: {
      initWebChat() {
        this.cozeWebSDK = new window.CozeWebSDK.WebChatClient({
          config: {
            botId: '7442533021452124199',
          },
          auth: {
            type: 'unauth',
            token: 'pat_jp3LtFJcJvQOzLFw2FJRyTO5cP99YJ9sBczvuvnQeunuuQhPNV026mtDDRfgJ1qQ',
            onRefreshToken: async () => 'pat_jp3LtFJcJvQOzLFw2FJRyTO5cP99YJ9sBczvuvnQeunuuQhPNV026mtDDRfgJ1qQ',
          },
          userInfo: {
            // id: '123',
            // url: 'http://njgdcm.72ch.cn/bot_icon3.png',
            // nickname: '3qweqweqwe',
          },
          ui: {
            base: {
              icon: 'http://gdstreet.72ch.cn/njgdai.png',
              layout: 'mobile',
              lang: 'zh-CN',
              zIndex: 1000,
            },
            //asstBtn: {
            //  isNeed: true,
            //},
            footer: {
              isShow: true,
              expressionText: 'Powered by 南京工业大学资产经营有限公司',
              linkvars: {
                name: {
                  text: 'A',
                  link: 'https://www.test1.com',
                },
                name1: {
                  text: 'B',
                  link: 'https://www.test2.com',
                },
              },
            },
            chatBot: {
              title: "南工大科技成果转化AI助手",
              uploadable: false,
              width: 800,
              el: undefined,
              onHide: () => {
                // todo...
                
              },
              onShow: () => {
                // todo...
                
              },
            },
          },
        });
  
        // 渲染到特定的 DOM 元素中
        this.cozeWebSDK.render('#web-chat');
      },
      destroyWebChat() {
        if (this.cozeWebSDK) {
          this.cozeWebSDK.destroy(); // 调用 destroy 方法
          this.cozeWebSDK = null; // 清理实例
        }
      },
    },
  };
  </script>
  
  <style scoped>
  #web-chat {
    /* 添加样式以适应聊天窗口 */
  }
  </style>
  