<template>
  <div id="app">
    <ChatComponent />
  </div>
</template>

<script>
import ChatComponent from './components/ChatComponent.vue'; // 确保路径正确

export default {
  name: 'App',
  components: {
    ChatComponent
  }
};
</script>

<style>
/* 你的样式 */
</style>
